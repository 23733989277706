.button {
  background-color: transparent;
  color: white;
  padding: .5rem 1rem;
  border-color: none;
  border-radius: .8rem;
}

.button:hover {
  background-color: rgb(52, 52, 52);

}