.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
}

.item {
  font-size: 2rem;
  width: 100%;
  border-radius: .4rem;
}