html {
  font-size: 10px;
  font-weight: 100;
}

ul {
  list-style: none;
}

body {
  background-color: rgb(62, 62, 62);
  color: white;
}